import React, {useContext} from 'react';
import type {PropsWithChildren, ReactNode} from 'react';
import cx from 'classnames';
import {I18nContext} from '../../../i18n';
import Button from '../Button';
import Modal from './Modal';

type Props = PropsWithChildren<{
	showModal: boolean;
	closeModal: () => void;
	title: string;
	header?: ReactNode;
	confirmAction?: () => Promise<void> | void;
	idButtonConfirm?: string;
	okTradKey?: string;
	cancelTradKey?: string;
	className?: string;
}>;

const ConfirmationModal = ({
	showModal,
	closeModal,
	confirmAction = () => null,
	title,
	header,
	children,
	idButtonConfirm,
	okTradKey = 'validate',
	cancelTradKey = 'cancel',
	className,
}: Props) => {
	const {translate} = useContext(I18nContext);

	const confirmAndCloseModal = async () => {
		await confirmAction();
		closeModal();
	};

	return (
		<Modal
			showModal={showModal}
			closeModal={closeModal}
			closable={false}
			title={title}
			header={
				header || (
					<div className="font-semibold text-lg lg:text-xl mb-4">
						{title}
					</div>
				)
			}
			className={cx('p-6 tablet:p-8', className)}
		>
			{children}
			<div className="flex flex-wrap flex-row-reverse justify-center max-w-full">
				<Button
					id={idButtonConfirm}
					variant="green"
					className="m-3 text-white max-w-72 w-full"
					onClick={confirmAndCloseModal}
					ariaLabel={translate(okTradKey)}
				>
					{translate(okTradKey)}
				</Button>
				<Button
					variant="custom"
					onClick={closeModal}
					className="m-3 max-w-72 w-full"
					ariaLabel={translate(cancelTradKey)}
				>
					{translate(cancelTradKey)}
				</Button>
			</div>
		</Modal>
	);
};

export default ConfirmationModal;
