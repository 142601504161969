import {getNames, registerLocale} from 'i18n-iso-countries';
import frTrad from 'i18n-iso-countries/langs/fr.json';
import type {OptionType} from '../../types/FormType';
import {CountryIsoAlpha2} from '../../types/generated-gateway-api-types';

registerLocale(frTrad);
export const COUNTRIES_LABEL_MAP = getNames('fr');
export const COUNTRY_OPTIONS: OptionType<string>[] = Object.entries(
	COUNTRIES_LABEL_MAP,
).map<OptionType<string>>(([value, countryLabel]) => ({
	value,
	label: String(countryLabel),
}));
export const COUNTRY_OPTION_DEFAULT = CountryIsoAlpha2.FR;
export const INITIAL_VALUES = {
	firstName: null,
	lastName: null,
	day: '',
	month: '',
	year: '',
	nationality: COUNTRY_OPTION_DEFAULT,
	addressStreet: null,
	addressZipCode: null,
	addressCity: null,
	countryOfResidence: COUNTRY_OPTION_DEFAULT,
	phone: null,
};
