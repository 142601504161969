import {gql} from '@apollo/client';

export const paperFragment = gql`
	fragment PaperFragment on Paper {
		id
		productId
		customerId
		pic
		kind
		status
		comment
	}
`;
export const UPLOAD_PAPERS_MUTATION = gql`
	mutation ($productId: String!, $papers: [CreatePaperInput!]!) {
		uploadPapers(productId: $productId, papers: $papers) {
			...PaperFragment
		}
	}
	${paperFragment}
`;
