import {useContext, useCallback} from 'react';
import {AnalyticsContext} from '../../contexts/analytics';
import {PaperKind} from '../../types/generated-gateway-api-types';

export enum UploadStatusEnum {
	SUCCESS = 'success',
	FAILURE = 'failure',
	RETRY = 'retry',
}

const trackingStatusToActionMap: Record<UploadStatusEnum, string> = {
	[UploadStatusEnum.SUCCESS]: 'accepted',
	[UploadStatusEnum.FAILURE]: 'rejected',
	[UploadStatusEnum.RETRY]: 'retry',
};

export type TrackUploadDocumentParams = {
	paperKind: PaperKind;
	status: UploadStatusEnum;
	label?: string;
};

const paperKindToGACategoryMap: Partial<Record<PaperKind, string>> = {
	[PaperKind.IDENTITY_CARD]: 'CNI',
	[PaperKind.BANK_DETAILS]: 'RIB',
	[PaperKind.CERTIFICATE_NO_PLEDGE]: 'CNG',
};

export const useTrackUploadDocumentEvent = () => {
	const {ga} = useContext(AnalyticsContext);

	return useCallback(
		({paperKind, status, label}: TrackUploadDocumentParams) => {
			if (!paperKindToGACategoryMap?.[paperKind])
				throw new Error(
					`Paperkind [${paperKind}] has no GA category set. Cannot track.`,
				);

			if (!trackingStatusToActionMap?.[status])
				throw new Error(
					`Upload status [${status}] has no GA action set. Cannot track.`,
				);

			ga(
				'send',
				'event',
				`Upload_${paperKindToGACategoryMap[paperKind]}`,
				`Download_${trackingStatusToActionMap[status]}`,
				label,
			);
		},
		[ga],
	);
};
