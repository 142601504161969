import React from 'react';
import cx from 'classnames';
import SuccessIcon from '../../../public/static/svg/successCheck.svg';

type Props = {
	isVisible: boolean;
	className?: string;
};

const SuccessCheck = ({isVisible, className, ...props}: Props) => (
	<SuccessIcon
		{...props}
		id="success-icon"
		className={cx(
			isVisible ? 'opacity-100 w-auto' : 'opacity-0 w-0',
			'transition-opacity duration-700 ease-in',
			className,
		)}
	/>
);

export default SuccessCheck;
