import React from 'react';
import cx from 'classnames';
import MarkdownIt from 'markdown-it';
import markdownItAttrs from 'markdown-it-attrs';

type Props = {
	source: string;
	className?: string;
	styles?: React.CSSProperties;
} & React.HTMLAttributes<HTMLDivElement>;

const Markdown = ({
	source,
	className = 'mb-8 px-4',
	styles,
	...props
}: Props) => {
	const md = new MarkdownIt();
	md.use(markdownItAttrs);
	return (
		<div
			style={styles}
			className={cx('markdown', className)} // eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{
				__html: md.render(source),
			}}
			{...props}
		/>
	);
};

export default Markdown;
