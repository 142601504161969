import {RefObject, useEffect} from 'react';

export function useOutsideAlerter(
	ref: RefObject<HTMLElement>,
	callback: () => void,
): void {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				if (callback) callback();
			}
		}

		document.addEventListener('click', handleClickOutside, true);
		document.addEventListener('keydown', handleClickOutside, true);

		return () => {
			document.removeEventListener('click', handleClickOutside, true);
			document.removeEventListener('keydown', handleClickOutside, true);
		};
	}, [ref]);
}
