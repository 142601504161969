import {useMutation, useQuery} from '@apollo/client';
import {useCallback, useContext, useMemo, useState} from 'react';
import {AuthContext, changeUserName} from '../../contexts/auth';
import {PROFILE_QUERY, UPDATE_CUSTOMER_MUTATION} from '../../queries/customer';
import type {Customer} from '../../types/generated-gateway-api-types';
import {computeApiError} from '../../utils/api-errors';
import {buildInitialValues} from './utils';

function useUpdateCustomer() {
	const {loggedIn} = useContext(AuthContext);
	const [updateCustomerErrors, setUpdateCustomerErrors] =
		useState<Record<string, string>>();

	const {data: profileData} = useQuery<{profile: Customer}>(PROFILE_QUERY, {
		skip: !loggedIn,
		fetchPolicy: 'cache-and-network',
	});

	const [updateCustomerMutation, {loading}] = useMutation(
		UPDATE_CUSTOMER_MUTATION,
		{
			update: (cache, {data}) => {
				setUpdateCustomerErrors(undefined);
				cache.writeQuery({
					query: PROFILE_QUERY,
					data: {
						profile: data.updateCustomer,
					},
				});
			},
			onError: errors => {
				setUpdateCustomerErrors(computeApiError(errors));
			},
		},
	);

	const profile = useMemo(
		() => buildInitialValues(profileData),
		[!!profileData, loggedIn],
	);

	const updateCustomer = useCallback(
		async ({
			day,
			month,
			year,
			address,
			...customer
		}: Record<string, any>) => {
			const response = await updateCustomerMutation({
				variables: {
					customer,
				},
			});
			changeUserName(customer.firstName, customer.lastName);
			return !!response?.data?.updateCustomer;
		},
		[],
	);

	return {
		profile,
		updateCustomer,
		updateCustomerErrors,
		loading,
	};
}

export default useUpdateCustomer;
