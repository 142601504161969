import {useCallback} from 'react';
import {useMutation} from '@apollo/client';
import {CUSTOMER_TRACKING_MUTATION} from '../../queries/customer';

export enum CUSTOMER_TRACKING_TYPE {
	SUCCESSFUL_LOGIN = 'SUCCESSFUL_LOGIN',
	ORDER_TRACKING_PAGE_VIEW = 'ORDER_TRACKING_PAGE_VIEW',
	DOCUMENT_UPLOAD_PAGE_VIEW = 'DOCUMENT_UPLOAD_PAGE_VIEW',
	SELLING_PROJECT_PAGE_VIEW = 'SELLING_PROJECT_PAGE_VIEW',
	PRODUCT_STATISTICS_PAGE_VIEW = 'PRODUCT_STATISTICS_PAGE_VIEW',
	MY_OFFERS_PAGE_VIEW = 'MY_OFFERS_PAGE_VIEW',
	MY_ALERTS_PAGE_VIEW = 'MY_ALERTS_PAGE_VIEW',
	MY_INVOICES_PAGE_VIEW = 'MY_INVOICES_PAGE_VIEW',
	PRICE_DECREASE_PAGE_VIEW = 'PRICE_DECREASE_PAGE_VIEW',
	REFRESH_QUOTATION_PAGE_VIEW = 'REFRESH_QUOTATION_PAGE_VIEW',
	FAVORITE_PAGE_VIEW = 'FAVORITE_PAGE_VIEW',
}

export const useCustomerTracking = () => {
	const [createTracking] = useMutation(CUSTOMER_TRACKING_MUTATION);

	const tracking = useCallback(
		async (type: CUSTOMER_TRACKING_TYPE, metadata = {}) => {
			const {
				data: {customerTracking: eventId},
			} = await createTracking({
				variables: {
					trackingEvent: {
						type,
						metadata,
					},
				},
			});

			return eventId;
		},
		[createTracking],
	);

	return tracking;
};
