import {gql} from '@apollo/client';

export const productFragmentBargaining = gql`
	fragment ProductFragmentBargaining on Product {
		id
		price
		priceArgus
		websiteStatus
		internalStatus
		thumb
		commission
		detail {
			id
			car {
				brand
				version
				carPackage
				energy
				gearbox
				motorization
			}
			entryIntoServiceDate
			mileage
			critAir
		}
		agent {
			id
			phone
			pic
			firstName
			lastName
		}
	}
`;
export const bargainingFragment = gql`
	fragment BargainingFragment on Bargaining {
		id
		offerId
		status
		createdAt
		answeredAt
		expireAt
		amount
		commission
		disabled
	}
`;
export const counterBargainingFragment = gql`
	fragment CounterBargainingFragment on CounterBargaining {
		offerId
		status
		createdAt
		updatedAt
		acceptPriceDrop
		expireAt
		amount
		commission
	}
`;
export const MY_BARGAININGS_QUERY = gql`
	query myBargainings {
		profile {
			myBargainings {
				...BargainingFragment
				product {
					...ProductFragmentBargaining
					order {
						status
					}
				}
				counterBargaining {
					...CounterBargainingFragment
				}
			}
		}
	}
	${productFragmentBargaining}
	${bargainingFragment}
	${counterBargainingFragment}
`;
export const BARGAINING_INFO_QUERY = gql`
	query bargainingInfos($productId: String!) {
		product(id: $productId) {
			...ProductFragmentBargaining
			myLastBuyerBargaining {
				...BargainingFragment
				counterBargaining {
					...CounterBargainingFragment
				}
			}
		}
	}
	${productFragmentBargaining}
	${bargainingFragment}
	${counterBargainingFragment}
`;
export const PRODUCT_FOR_BARGAINING_QUERY = gql`
	query product($id: String!) {
		product(id: $id) {
			...ProductFragmentBargaining
			bargainings {
				id
				status
			}
		}
		${productFragmentBargaining}
	}
`;
export const COUNTER_OFFER_QUERY = gql`
	query bargaining($id: ID!) {
		bargaining: bargainingByOfferId(id: $id) {
			...BargainingFragment
			isPendingPriceDrop
			product {
				...ProductFragmentBargaining
			}
		}
	}
	${bargainingFragment}
	${productFragmentBargaining}
`;
export const CREATE_BARGAINING_MUTATION = gql`
	mutation createBargaining($bargaining: BargainingInput!) {
		createBargaining(bargaining: $bargaining) {
			...BargainingFragment
		}
	}
	${bargainingFragment}
`;
export const ACCEPT_OFFER_MUTATION = gql`
	mutation acceptOffer($offerId: ID!) {
		acceptOffer(offerId: $offerId) {
			...BargainingFragment
		}
	}
	${bargainingFragment}
`;
export const REFUSE_OFFER_MUTATION = gql`
	mutation refuseOffer($offerId: ID!) {
		refuseOffer(offerId: $offerId) {
			...BargainingFragment
		}
	}
	${bargainingFragment}
`;
export const SEND_COUNTER_OFFER_MUTATION = gql`
	mutation refuseAndCounterOffer(
		$offerId: ID!
		$amount: Int!
		$acceptPriceDrop: Boolean
	) {
		refuseAndCounterOffer(
			offerId: $offerId
			amount: $amount
			acceptPriceDrop: $acceptPriceDrop
		) {
			...BargainingFragment
		}
	}
	${bargainingFragment}
`;
export const ACCEPT_PRICE_DROP_MUTATION = gql`
	mutation acceptPriceDrop($offerId: ID!) {
		acceptPriceDrop(offerId: $offerId) {
			...BargainingFragment
		}
	}
	${bargainingFragment}
`;
