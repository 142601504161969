import {useContext} from 'react';
import {useQuery} from '@apollo/client';
import libPhoneNumber from 'libphonenumber-js';
import {AnalyticsContext} from '../../contexts/analytics';
import {AuthContext} from '../../contexts/auth';
import {PHONE_NUMBER_QUERY} from '../../queries/phoneNumber';

type PhoneType = {
	number: string;
	href: string;
};
const utmPhoneMap: Record<string, string> = {
	paruvendu: '01 84 80 59 43',
	zoomcar: '09 80 80 41 74',
	google: '01 84 80 95 53',
	lbc: '09 80 80 51 69',
	sendinblue: '09 80 80 59 86',
	bng: '09 80 80 32 53',
	adw: '09 80 80 93 94',
	fb: '09 80 80 42 08',
	blog: '09 80 80 72 69',
	allianz: '09 80 80 29 39',
	awin: '09 80 80 76 55',
	lacentrale: '09 80 80 74 11',
};

const defaultPhone: PhoneType = {
	number: '01 84 80 00 15',
	href: 'tel:+33-1-84-80-00-15',
};

const formatPhoneNumber = (phone: string): PhoneType => {
	const parsedPhoneNumber = libPhoneNumber(phone, 'FR');

	if (!parsedPhoneNumber) {
		return defaultPhone;
	}

	return {
		number: parsedPhoneNumber.formatNational(),
		href: parsedPhoneNumber.getURI(),
	};
};

function usePhoneNumber(productAgentPhoneNumber?: string | null): PhoneType {
	const {tracker} = useContext(AnalyticsContext);
	const {customerId} = useContext(AuthContext);

	if (productAgentPhoneNumber) {
		return formatPhoneNumber(productAgentPhoneNumber);
	}

	const {data} = useQuery(PHONE_NUMBER_QUERY, {
		fetchPolicy: 'cache-and-network',
		skip: !customerId,
	});

	if (data && data.phoneNumber) {
		return formatPhoneNumber(data.phoneNumber);
	}

	if (tracker?.source && utmPhoneMap[tracker?.source]) {
		return formatPhoneNumber(utmPhoneMap[tracker.source]);
	}

	return defaultPhone;
}

export default usePhoneNumber;
