import React from 'react';

const Line = ({width}: {width: string}) => (
	<div className="absolute w-full flex items-center z-70">
		<div
			className="transition-all duration-700 bg-green-550 h-1 "
			style={{
				width,
			}}
		/>
		<div className="h-2 max-w-2 flex-1" />
	</div>
);

export default Line;
