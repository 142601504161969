import {useEffect, useState} from 'react';
import throttle from 'lodash/throttle';

export function useScrollPosition() {
	const [scrollPosition, setScrollPosition] = useState<number>(0);

	useEffect(() => {
		if (typeof window === 'undefined') return undefined;

		const handleScrollThrottled = throttle(() => {
			setScrollPosition(window.scrollY ?? window.pageYOffset ?? 0);
		}, 300);

		window.addEventListener('scroll', handleScrollThrottled, {
			passive: true,
		});

		return () => {
			window.removeEventListener('scroll', handleScrollThrottled);
		};
	}, []);

	return scrollPosition;
}
