import React, {useContext} from 'react';
import type {ComponentType} from 'react';
import {useAnchorState} from '../../../hooks';
import {I18nContext} from '../../../i18n';
import TimesLight from '../../../public/static/svg/times-light.svg';

type Props = {
	title: string;
	icon?: ComponentType<any>;
};

const Header: React.FC<Props> = ({title, icon: Icon}) => {
	const [, removeAnchor] = useAnchorState();
	const {translate} = useContext(I18nContext);
	return (
		<div className="text-darkGray-900 text-xl flex items-start items leading-none">
			{!!Icon && (
				<Icon className="w-5 h-5 fill-current self-center mr-2" />
			)}
			<div className="mt-1">{title}</div>
			<button
				type="button"
				onClick={removeAnchor}
				className="text-2xl focus:outline-none text-blue-275 ml-auto border border-gray-500 rounded flex p-1"
			>
				<TimesLight className="text-gray-900 inline mt-1 mr-2" />
				<span className="text-darkGray-900 text-base ">
					{translate('modal.close')}
				</span>
			</button>
		</div>
	);
};

export default Header;
