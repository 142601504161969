import {toast} from 'react-toastify';

export enum VariantNotify {
	Success = 'SUCCESS',
	Error = 'ERROR',
	Warn = 'WARN',
	Info = 'INFO',
}

export function notify(text: React.ReactNode, variant: VariantNotify): void {
	VARIANT_FN[variant](text);
}

const VARIANT_FN = {
	[VariantNotify.Success]: toast.success,
	[VariantNotify.Error]: toast.error,
	[VariantNotify.Warn]: toast.warn,
	[VariantNotify.Info]: toast.info,
};
