import React, {useContext} from 'react';
import cx from 'classnames';
import {I18nContext} from '../../../i18n';
import LeafIcon from '../../../public/static/theme-capcar/leaf-duotone.svg';
import ArrowIcon from '../../../public/static/theme-capcar/chevron-right-light.svg';
import Link, {LinkType} from '../Link';

type Props = {
	text: string;
	url: string;
	as?: string | null | undefined;
	tracking: LinkType;
	className?: string;
	linkClassName?: string;
};

const BonusConversionLink = ({
	text,
	url,
	as,
	tracking,
	className,
	linkClassName,
}: Props) => {
	const {translate} = useContext(I18nContext);
	return (
		<Link
			type={tracking}
			href={url}
			as={as}
			tracking={{
				category: 'btn_learn_more_prime_conversion',
			}}
			className={className}
		>
			<a
				{...(tracking === LinkType.EXTERNAL && {
					rel: 'noopener noreferrer',
					target: '_blank',
				})}
				className={cx(
					'w-full flex items-center px-3 tablet:px-6 py-2_5 tablet:py-3 font-bold rounded text-green-650 hover:text-green-650 bg-green-150 text-xs sm:text-sm laptop:text-xs xl:text-sm',
					linkClassName,
				)}
			>
				<LeafIcon className="hidden md:inline pr-2" />
				<span className="flex flex-col ml-2">
					<p>{translate(text)}</p>
					<p className="inline md:hidden">{translate('learnMore')}</p>
				</span>

				<span className="md:bg-white rounded px-1 md:px-6 md:py-2 text-green-650 hover:text-green-650 hover:text-green-650 block ml-auto">
					<span className="hidden md:block">
						{translate('learnMore')}
					</span>
					<ArrowIcon className="block md:hidden fill-green-500" />
				</span>
			</a>
		</Link>
	);
};

export default BonusConversionLink;
