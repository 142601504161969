import React from 'react';
import cx from 'classnames';
import SuccessIcon from '../../../public/static/svg/successCheck.svg';

type Props = {
	message: string;
	className?: string;
};

const SuccessMessage: React.FC<Props> = ({message, className, ...props}) => (
	<div
		{...props}
		className={cx(
			'flex items-center p-4 rounded-lg bg-success-100 text-label-green',
			className,
		)}
	>
		<SuccessIcon className="mr-2 w-18 fill-current " />
		<span className="text-white">{message}</span>
	</div>
);

export default SuccessMessage;
