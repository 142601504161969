import React from 'react';
import {getReactBreakpoints} from '../../../breakpoints.config';

type Props = {
	defaultSrc: string;
	srcs: Record<string, string>;
	alt: string;
	className?: string;
};

const Picture = ({defaultSrc, srcs, className, alt}: Props) => {
	const breakpoints = getReactBreakpoints();
	return (
		<picture>
			{Object.entries(srcs).map(([breakpoint, srcUrl]) => (
				<source
					key={breakpoint}
					media={`(min-width: ${breakpoints[breakpoint]}px)`}
					srcSet={srcUrl}
				/>
			))}
			<img src={defaultSrc} className={className} alt={alt} />
		</picture>
	);
};

export default Picture;
