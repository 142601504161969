import React from 'react';
import cx from 'classnames';

type Props = {
	icon?: React.ReactNode;
	label?: React.ReactNode;
	className?: string;
	children?: React.ReactNode;
	style?: Record<string, any>;
};

const Block = ({
	icon,
	className = 'p-5 rounded-lg',
	label,
	children,
	style,
}: Props) => (
	<div className={cx('bg-white', className)} style={style}>
		{label && (
			<div className={cx('flex items-center', !!children && 'mb-4')}>
				<span className="mr-2">{icon}</span>
				{label}
			</div>
		)}
		{children}
	</div>
);

export default Block;
