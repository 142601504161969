import React, {useContext} from 'react';
import {DialogContent, DialogOverlay} from '@reach/dialog';
import {motion} from 'framer-motion';
import cx from 'classnames';
import type {ReactNode, PropsWithChildren} from 'react';
import {I18nContext} from '../../../i18n';
import CrossIcon from '../../../public/static/svg/cross.svg';

type Props = PropsWithChildren<{
	showModal: boolean;
	closeModal: () => void;
	closable?: boolean;
	title: string;
	header?: ReactNode;
	className?: string;
}>;

// Since `motion()` removes the `children` props from the typings, we
// have to type-cast our "motioned" component to it's original type.
const AnimatedContent = motion.create(
	DialogContent,
) as unknown as typeof DialogContent;
const animationParams = {
	initial: {
		opacity: 0.5,
		y: 50,
	},
	animate: {
		opacity: 1,
		y: 0,
	},
	transition: {
		ease: 'easeOut',
		duration: 0.2,
	},
};

const Modal = ({
	showModal,
	closeModal,
	header,
	children,
	closable = true,
	className = '',
	title,
}: Props) => {
	const {translate} = useContext(I18nContext);
	return (
		<DialogOverlay isOpen={showModal} onDismiss={closeModal}>
			<AnimatedContent
				{...animationParams}
				className={cx(
					'shadow rounded-t-xl md:rounded-lg text-darkGray-500 fixed sm:relative bottom-0 sm:bottom-auto p-4 max-h-full overflow-y-auto',
					className,
				)}
				aria-label={title}
			>
				{header}
				{children}
				{closable && (
					<button
						type="button"
						onClick={closeModal}
						className="close absolute top-5 right-5 text-2xl focus:outline-none text-blue-275"
						aria-label={translate('close')}
					>
						<CrossIcon className="fill-blue-275 h-5 w-5" />
					</button>
				)}
			</AnimatedContent>
		</DialogOverlay>
	);
};

export default Modal;
