import {motion} from 'framer-motion';
import React from 'react';

const Overlay = ({open}: {open: boolean}) => (
	<motion.div
		className="fixed top-0 bottom-0 tablet:hidden"
		data-reach-dialog-overlay
		initial="collapsed"
		animate={open ? 'open' : 'collapsed'}
		transition={{
			duration: 0.2,
		}}
		variants={{
			open: {
				opacity: 1,
				visibility: 'visible',
			},
			collapsed: {
				opacity: 0,
				transitionEnd: {
					visibility: 'hidden',
				},
			},
		}}
	/>
);

export default Overlay;
