import {gql} from '@apollo/client';

export const UPDATE_PASSWORD_QUERY = gql`
	mutation ($customerId: Int, $token: String, $password: String) {
		updatePassword(
			customerId: $customerId
			token: $token
			password: $password
		) {
			email
		}
	}
`;
export const RESET_PASSWORD_QUERY = gql`
	mutation ($email: Email, $redirectionUrl: String) {
		resetPassword(email: $email, redirectionUrl: $redirectionUrl)
	}
`;
