import {useAnimation, useInView, AnimationControls} from 'framer-motion';
import React, {useEffect, useRef} from 'react';

const useScrollAnimation = (): [
	React.RefObject<HTMLDivElement>,
	AnimationControls,
] => {
	const controls = useAnimation();

	// Utilise le hook `useInView` de `framer-motion`
	const ref = useRef(null);
	const isInView = useInView(ref, {once: true}); // Pas besoin de react-intersection-observer

	useEffect(() => {
		if (isInView) {
			controls.start('visible');
		}
	}, [controls, isInView]);

	return [ref, controls];
};

export default useScrollAnimation;
