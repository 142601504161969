import React, {ReactElement, cloneElement, useContext} from 'react';
import NextLink from 'next/link';
import type {UrlObject} from 'url';
import {useRouter} from 'next/router';
import {AnalyticsContext} from '../../../contexts/analytics';
import {TrackingType} from '../../../types/TrackingType';

export enum LinkType {
	IN_APP = 'IN_APP',
	TRACKED = 'TRACKED',
	ANCHOR = 'ANCHOR',
	EXTERNAL = 'EXTERNAL',
	PHONE = 'PHONE',
}

type Props = React.PropsWithChildren<{
	href: string | UrlObject;
	type?: LinkType;
	ariaLabel?: string;
	tracking?: TrackingType;
	className?: string;
	prefetch?: boolean;
	replace?: boolean;
	as?: string;
	rel?: string;
	onClick?: (arg0?: any) => any; // https://github.com/storybookjs/storybook/issues/4873
}>;

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
const Link: React.FC<Props> = ({
	href,
	type,
	children,
	tracking,
	className,
	replace = false,
	onClick,
	...rest
}) => {
	const {withTracking, ga} = useContext(AnalyticsContext);
	const router = useSafeRouter();

	const handleClick = () => {
		if (tracking) {
			ga(
				'send',
				'event',
				tracking.category,
				tracking.action || 'click',
				tracking.label,
				tracking.value != null ? Math.round(tracking.value) : undefined,
			);
		}

		if (onClick) onClick();
	};

	const hasHandleClick = tracking || onClick || className;

	if (type === LinkType.IN_APP) {
		if (hasHandleClick) {
			return (
				<span onClick={handleClick} className={className}>
					<NextLink
						{...rest}
						replace={replace}
						href={href}
						legacyBehavior
					>
						{children}
					</NextLink>
				</span>
			);
		}

		if (typeof href === 'string' && href === router?.asPath) {
			return children as ReactElement;
		}

		return (
			<NextLink {...rest} replace={replace} href={href} legacyBehavior>
				{children}
			</NextLink>
		);
	}

	if (typeof href !== 'string') {
		throw new Error(
			"'href' should be a string for links other than 'IN_APP'",
		);
	}

	if (type === LinkType.TRACKED) {
		const trackedLink = cloneElement(children as React.ReactElement<any>, {
			href: withTracking ? withTracking(href) : href,
		});
		return (
			<span onClick={handleClick} className={className}>
				{trackedLink}
			</span>
		);
	}

	if (type === LinkType.ANCHOR) {
		const anchorClick = () => {
			if (replace) {
				window.history.replaceState(null, null, href);
				window.dispatchEvent(new Event('hashchange'));
				return false;
			}

			return true;
		};

		const link = cloneElement(children as React.ReactElement<any>, {
			href,
			onClick: anchorClick,
		});
		return (
			<span onClick={handleClick} className={className}>
				{link}
			</span>
		);
	}

	const link = cloneElement(children as React.ReactElement<any>, {
		href,
	});

	if (hasHandleClick) {
		return (
			<span onClick={handleClick} className={className}>
				{link}
			</span>
		);
	}

	return link;
};

function useSafeRouter() {
	try {
		return useRouter();
	} catch {
		return null;
	}
}

export default Link;
