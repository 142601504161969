const breakpoints = {
	mobile: 375,
	sm: 640,
	md: 768,
	tablet: 1024,
	lg: 1100,
	laptop: 1280,
	desktop: 1366,
	xl: 1500,
	xxl: 1600,
};

module.exports.getTailwindBreakpoints = () =>
	Object.keys(breakpoints).reduce((acc, key) => {
		acc[key] = `${breakpoints[key]}px`;
		return acc;
	}, {});

module.exports.getReactBreakpoints = () => breakpoints;
