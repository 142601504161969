export const ERROR_MESSAGES = Object.freeze({
	EMAIL_ALREADY_EXIST:
		'Cette adresse e-mail correspond déjà à un compte CapCar.',
	ERROR_UPDATE_QUOTATION:
		"Une erreur est survenue durant l'envoi de la quotation",
	UNKNOWN_EMAIL:
		'Cet email nous est inconnu. Vérifiez bien votre adresse email.',
	DELETED_ACCOUNT:
		'Cet email nous est inconnu. Vérifiez bien votre adresse email.',
	DEFAULT:
		"Oups, une erreur s'est produite. Merci de réessayer dans quelques instants.",
});
