import {useCallback, useState} from 'react';
import {FetchResult, useMutation} from '@apollo/client';
import {RESET_PASSWORD_QUERY} from '../../queries/password';
import {computeApiError} from '../../utils/api-errors';

type DataResetPassword = {
	email: string;
};
type UseResetPasswordResponse = {
	resetPasswordErrors?: Record<string, string>;
	loading: boolean;
	resetPassword: (arg0: DataResetPassword) => Promise<FetchResult>;
};

function useResetPassword(
	redirectPasswordForgetUrl: string,
): UseResetPasswordResponse {
	const [resetPasswordErrors, setResetPasswordErrors] =
		useState<Record<string, string>>();
	const [resetPasswordMutation, {loading}] = useMutation(
		RESET_PASSWORD_QUERY,
		{
			onError: error => {
				setResetPasswordErrors(computeApiError(error));
			},
		},
	);
	const resetPassword = useCallback(
		async ({email}: DataResetPassword) =>
			resetPasswordMutation({
				variables: {
					email,
					redirectionUrl: redirectPasswordForgetUrl,
				},
			}),
		[],
	);
	return {
		resetPasswordErrors,
		loading,
		resetPassword,
	};
}

export default useResetPassword;
