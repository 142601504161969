import {useCallback, useState} from 'react';
import {useQuery, useMutation, useApolloClient} from '@apollo/client';
import {useRouter} from 'next/compat/router';
import {login} from '../../contexts/auth';
import {UPDATE_PASSWORD_QUERY} from '../../queries/password';
import {VALIDATE_TOKEN_QUERY} from '../../queries/validateToken';
import {computeApiError} from '../../utils/api-errors';
import {fetchPublicApi} from '../../utils/fetchApi';

type DataUpdatePassword = {
	password: string;
};
type UseUpdatePasswordResponse = {
	validToken: boolean;
	loading: boolean;
	errorsUpdatePassword?: Record<string, string>;
	updatePassword: (arg0: DataUpdatePassword) => Promise<boolean>;
};

function useUpdatePassword(): UseUpdatePasswordResponse {
	const apolloClient = useApolloClient();
	const [errorsUpdatePassword, setErrorsUpdatePassword] =
		useState<Record<string, string>>();
	const [loading, setLoading] = useState(false);
	const {query, push} = useRouter();
	const {customerId, token, redirectionUrl} = query;
	const {data} = useQuery(VALIDATE_TOKEN_QUERY, {
		variables: {
			customerId: Number(customerId),
			token,
		},
	});
	const [updatePasswordMutation] = useMutation(UPDATE_PASSWORD_QUERY);
	const updatePassword = useCallback(async ({password}) => {
		setLoading(true);

		try {
			const {
				data: {
					updatePassword: {email},
				},
			} = await updatePasswordMutation({
				variables: {
					password,
					token,
					customerId: Number(customerId),
				},
			});
			fetchPublicApi('/login', {
				method: 'POST',
				body: {
					username: email,
					password,
				},
			}).then(async res => {
				login({
					token: res.token,
					id: res.id,
					firstName: res.firstName,
					lastName: res.lastName,
					email: res.email,
					apolloClient,
				});
				push({
					pathname: `/${redirectionUrl}`,
					query: {
						isPasswordSaved: true,
					},
				});
			});
			return true;
		} catch (error) {
			setErrorsUpdatePassword(computeApiError(error));
			setLoading(false);
			return false;
		}
	}, []);
	return {
		validToken: data?.validateToken,
		loading,
		errorsUpdatePassword,
		updatePassword,
	};
}

export default useUpdatePassword;
