import React, {useState} from 'react';
import Overlay from './Overlay';
import Tooltip from './Tooltip';
import InfoIcon from '../../../public/static/theme-capcar/info-circle-light.svg';
import InfoOpenIcon from '../../../public/static/theme-capcar/info-circle-solid.svg';

type Props = {
	content: React.ReactNode;
};

const SuperTooltip = ({content}: Props) => {
	const [open, setOpen] = useState(false);

	const show = () => setOpen(true);

	const hide = () => setOpen(false);

	return (
		// focus & blur are for mobile, mouseEvent handle click for desktop
		<>
			<button
				className="relative focus:outline-none"
				type="button"
				onBlur={hide}
				onFocus={show}
				onMouseEnter={show}
				onMouseLeave={hide}
			>
				{open ? (
					<InfoOpenIcon className="w-4 ml-2 fill-current" />
				) : (
					<InfoIcon className="w-4 ml-2 fill-current" />
				)}

				<Tooltip open={open}>{content}</Tooltip>
			</button>
			<Overlay open={open} />
		</>
	);
};

export default SuperTooltip;
