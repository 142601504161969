import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import {useMemo} from 'react';

type LeftTimes = {
	hours: number;
	minutes: number;
	totalMinutes: number;
};
export const useTimeLeft: (arg0: Date | null | undefined) => LeftTimes = date =>
	useMemo(() => {
		if (!date) {
			return {
				hours: 0,
				minutes: 0,
				totalMinutes: 0,
			};
		}

		const leftHours = differenceInHours(date, new Date());
		const leftMinutes = differenceInMinutes(date, new Date());
		return {
			hours: leftHours,
			minutes: leftMinutes % 60,
			totalMinutes: leftMinutes,
		};
	}, [date]);
