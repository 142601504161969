import React, {PropsWithChildren} from 'react';
import cx from 'classnames';

type Props = PropsWithChildren<{className?: string}>;

const CenteredContainer = ({children, className}: Props) => (
	<div
		className={cx('container flex justify-center py-8 mx-auto', className)}
	>
		{children}
	</div>
);

export default CenteredContainer;
