import {
	ApolloClient,
	InMemoryCache,
	from,
	defaultDataIdFromObject,
} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {createUploadLink} from 'apollo-upload-client';
import cookie from 'js-cookie';
import fetch from 'isomorphic-unfetch';
import config from '../../config';
import {logout} from '../auth';
import type {ApolloClientProps} from './types';

const {PUBLIC_API_URI, ENV_NAME} = config;

const buildAuthLink = (token?: string, customerId?: string) => {
	const envName = ENV_NAME ?? 'development';
	return setContext((_, {headers}) => {
		const cookieToken =
			envName === 'production'
				? cookie.get('token')
				: cookie.get(`${envName}_token`);
		const cookieCustomerId =
			envName === 'production'
				? cookie.get('customerId')
				: cookie.get(`${envName}_customerId`);
		return {
			headers: {
				...headers,
				authorization: `Bearer ${token ?? cookieToken}`,
				'customer-id': customerId ?? cookieCustomerId,
			},
		};
	});
};

/**
 * Creates and configures the ApolloClient
 * @param  {Object} [initialState={}]
 * @param token
 * @param customerId
 */
export const createApolloClient = ({
	initialState = {},
	token,
	customerId,
}: ApolloClientProps) => {
	const authLink = buildAuthLink(token, customerId);
	const linkError = onError(({graphQLErrors}) => {
		if (
			graphQLErrors &&
			graphQLErrors[0].extensions.code === 'UNAUTHENTICATED'
		) {
			logout();
		}
	});
	const uploadLink = createUploadLink({
		uri: `${PUBLIC_API_URI}/graphql`,
		fetch,
		headers: {
			'Apollo-Require-Preflight': 'true',
		},
	});
	return new ApolloClient({
		ssrMode: typeof window === 'undefined',
		// Disables forceFetch on the server (so queries are only run once)
		link: from([authLink, linkError, uploadLink]),
		cache: new InMemoryCache({
			dataIdFromObject: object => defaultDataIdFromObject(object),
		}).restore(initialState),
		connectToDevTools: ENV_NAME !== 'production',
	});
};
