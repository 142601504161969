import {useContext, useEffect} from 'react';
import {useRouter} from 'next/compat/router';
import {I18nContext} from '../../i18n';
import {notify, VariantNotify} from '../../utils/notify';

function useIsPasswordSaved() {
	const {translate} = useContext(I18nContext);
	const {
		query: {isPasswordSaved = false},
	} = useRouter();
	useEffect(() => {
		if (isPasswordSaved) {
			notify(translate('passwordSetSuccess'), VariantNotify.Success);
		}
	}, [isPasswordSaved]);
}

export default useIsPasswordSaved;
