import React from 'react';
import cx from 'classnames';

type Props = {
	text: string;
	className?: string;
};

const HorizontalSeparation = ({text, className}: Props) => (
	<div
		className={cx(
			'w-full text-center border-b border-gray-18 leading-0_1',
			className,
		)}
	>
		<span className="font-bold py-0 px-2_5 uppercase bg-white">{text}</span>
	</div>
);

export default HorizontalSeparation;
