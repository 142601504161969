import type {PropsWithChildren} from 'react';
import React from 'react';
import cx from 'classnames';
import ErrorCancel from '../../../public/static/svg/errorCancel.svg';

type Props = PropsWithChildren<{
	className?: string;
}>;

const ErrorMessage = ({children, className, ...props}: Props) => (
	<div
		{...props}
		className={cx(
			'flex items-center p-4 text-sm font-black rounded-lg bg-error-100 text-label-red',
			className,
		)}
	>
		<div className="w-16">
			<ErrorCancel width={50} className="mr-2 fill-current" />
		</div>
		{children}
	</div>
);

export default ErrorMessage;
