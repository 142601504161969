import {useState, useEffect, useCallback, useRef} from 'react';
import {useRouter} from 'next/compat/router';

const useAnchorState = (): [string, () => void] => {
	const isSSR = typeof window === 'undefined';

	if (isSSR) {
		return ['', () => undefined];
	}

	const router = useRouter();
	const anchorRef = useRef<string>(window.location.hash.split('#')[1]);
	const [, forceUpdate] = useState(0);

	const removeAnchor = useCallback(() => {
		const location = window.location.href.split('#')[0];
		router
			.replace(location, undefined, {
				scroll: false,
			})
			.then(() => {
				window.dispatchEvent(new Event('hashchange'));
			});
	}, []);

	const onHashChange = useCallback(() => {
		if (anchorRef.current !== window.location.hash.slice(1)) {
			anchorRef.current = window.location.hash.slice(1);
			forceUpdate(prev => prev + 1);
		}
	}, []);

	useEffect(() => {
		window.addEventListener('hashchange', onHashChange);
		return () => window.removeEventListener('hashchange', onHashChange);
	}, []);
	return [anchorRef.current, removeAnchor];
};

export default useAnchorState;
