import React from 'react';
import {DialogContent, DialogOverlay} from '@reach/dialog';
import cx from 'classnames';
import {motion} from 'framer-motion';
import type {ReactNode, PropsWithChildren} from 'react';
import useAnchorState from '../../../hooks/useAnchorState';
import {MODALS_NAMES} from './const';

type Props = PropsWithChildren<{
	modalName: (typeof MODALS_NAMES)[keyof typeof MODALS_NAMES];
	title: string;
	header?: ReactNode;
	className?: string;
}>;

// Since `motion()` removes the `children` props from the typings, we
// have to type-cast our "motioned" component to it's original type.
const AnimatedContent = motion.create(
	DialogContent,
) as unknown as typeof DialogContent;

const AnchorModal = ({
	modalName,
	header,
	children,
	className = '',
	title,
}: Props) => {
	const [anchor, removeAnchor] = useAnchorState();
	const animationParams = {
		initial: {
			opacity: 0.5,
			y: 50,
		},
		animate: {
			opacity: 1,
			y: 0,
		},
		transition: {
			ease: 'easeOut',
			duration: 0.2,
		},
	};
	return (
		<DialogOverlay isOpen={anchor === modalName} onDismiss={removeAnchor}>
			<AnimatedContent
				{...animationParams}
				className={cx(
					'shadow rounded-t-xl md:rounded-lg text-darkGray-500 fixed sm:relative bottom-0 sm:bottom-auto p-4 max-h-full overflow-y-auto',
					className,
				)}
				aria-label={title}
			>
				{header}
				{children}
			</AnimatedContent>
		</DialogOverlay>
	);
};

export default AnchorModal;
