import {useState, useEffect, useContext} from 'react';
import throttle from 'lodash/throttle';
import {RequestContext} from '../../contexts/request';
import {getReactBreakpoints} from '../../breakpoints.config';

const breakpoints = getReactBreakpoints();
type BreakpointType = keyof typeof breakpoints;

const getWindowInnerWidth = () => {
	if (typeof window === 'undefined') return 0;
	return window.innerWidth;
};

const matchMin = ({
	min,
	conf,
	innerWidth,
}: {
	min: BreakpointType;
	conf: Record<string, any>;
	innerWidth: number;
}): boolean => innerWidth >= conf[min];

const matchMax = ({
	max,
	conf,
	innerWidth,
}: {
	max: BreakpointType;
	conf: Record<string, any>;
	innerWidth: number;
}): boolean => innerWidth < conf[max];

type MatchBreakpointOptions = {
	min?: BreakpointType;
	max?: BreakpointType;
	conf: Record<string, any>;
	innerWidth: number;
};

const match = ({
	min,
	max,
	conf,
	innerWidth,
}: MatchBreakpointOptions): boolean => {
	if (min && max)
		return (
			matchMin({
				min,
				conf,
				innerWidth,
			}) &&
			matchMax({
				max,
				conf,
				innerWidth,
			})
		);
	if (min)
		return matchMin({
			min,
			conf,
			innerWidth,
		});
	if (max)
		return matchMax({
			max,
			conf,
			innerWidth,
		});
	return false;
};

export type MatchBreakpointType = (arg0: {
	min?: BreakpointType;
	max?: BreakpointType;
}) => boolean;
type UseBreakPointsType = () => {
	matchBreakpoint: MatchBreakpointType;
	width: number;
};

const useBreakpoints: UseBreakPointsType = () => {
	const {isMobile} = useContext(RequestContext);
	const [innerWidth, setInnerWidth] = useState(isMobile ? 0 : breakpoints.lg);
	useEffect(() => {
		const calcInnerWidth = throttle(() => {
			setInnerWidth(getWindowInnerWidth());
		}, 200);
		setInnerWidth(getWindowInnerWidth());
		window.addEventListener('resize', calcInnerWidth);
		return () => window.removeEventListener('resize', calcInnerWidth);
	}, []);

	const matchBreakpoint: MatchBreakpointType = ({min, max}) =>
		match({
			min,
			max,
			conf: breakpoints,
			innerWidth,
		});

	return {
		matchBreakpoint,
		width: innerWidth,
	};
};

export const useMedia = (breakpoint: BreakpointType) => {
	const [isWider, setIsWider] = useState(null);

	const updateState = ev => setIsWider(ev.matches);

	useEffect(() => {
		// We can use mediaList here since useEffect is only executed cli-side
		const mediaList: MediaQueryList | any = window.matchMedia(
			`(min-width: ${breakpoints[breakpoint]}px)`,
		);
		setIsWider(mediaList.matches);

		if ('addEventListener' in mediaList) {
			mediaList.addEventListener('change', updateState);
			return () => mediaList.removeEventListener('change', updateState);
		}

		// Fallback for old Safari (version <= 13)
		mediaList.addListener(updateState);
		return () => mediaList.removeListener(updateState);
	}, [breakpoint]);
	return isWider;
};
export default useBreakpoints;
