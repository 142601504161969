import {FORM_ERROR} from 'final-form';
import mapValues from 'lodash/mapValues';
import {ERROR_MESSAGES} from '../i18n/api-error-fr';

export const computeApiError = (error): Record<string, string> => {
	if (!error.graphQLErrors || error.graphQLErrors.length === 0) {
		return {
			[FORM_ERROR]: ERROR_MESSAGES.DEFAULT,
		};
	}

	const graphQLError = error.graphQLErrors[0];

	if (
		graphQLError.message === 'VALIDATION_ERROR' &&
		graphQLError.extensions
	) {
		return mapValues(
			graphQLError.extensions.violations,
			e => ERROR_MESSAGES[e] || e,
		);
	}

	return {
		[FORM_ERROR]: ERROR_MESSAGES.DEFAULT,
	};
};
