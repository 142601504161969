import React from 'react';
import cx from 'classnames';

type Props = {
	className?: string;
};

const VerticalBars = ({className = 'bg-lightBlue-325'}: Props) => (
	<div className="flex justify-center">
		{[24, 15, 30].map((width, index) => (
			<div
				className={cx(
					`h-2_5 mt-5 rounded-xl  w-${width} inline-block mr-4 mx-${
						index * 2
					}`,
					className,
				)}
				key={width}
			/>
		))}
	</div>
);

export default VerticalBars;
