export enum ShareStrategy {
	Clipboard = 'CLIPBOARD',
	Email = 'EMAIL',
	Whatsapp = 'WHATSAPP',
	Facebook = 'FACEBOOK',
	Twitter = 'TWITTER',
	Native = 'NATIVE',
}

export type ShareablePlatform = {
	strategy: ShareStrategy;
	transKey: string;
};

export type ShareData = {
	title: string;
	url: string;
	text?: string;
	email?: {
		subject?: string;
		body?: string;
	};
};

export type ShareStrategyHandler = (
	data: ShareData,
	onShareDone?: (error?: Error) => void,
) => Promise<void> | null | string | void;

export type NativeShareProductFn = (title: string, url: string) => void;
