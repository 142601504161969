import React from 'react';
import {ApolloProvider} from '@apollo/client';
import nextCookie from 'next-cookies';
import {NextPage} from 'next';
import {getAuthCookies} from '../auth';
import {createApolloClient} from './client';
import type {ApolloClientProps} from './types';

let apolloClient = null;

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} initialState
 * @param  {String} token
 * @param  {String} customerId
 */
const initApolloClient = ({
	initialState = {},
	token = null,
	customerId = null,
}: ApolloClientProps) => {
	// Make sure to create a new client for every server-side request so that data
	// isn't shared between connections (which would be bad)
	if (typeof window === 'undefined') {
		return createApolloClient({
			initialState,
			token,
			customerId,
		});
	}

	// Reuse client on the client-side
	if (!apolloClient) {
		apolloClient = createApolloClient({
			initialState,
			token,
			customerId,
		});
	}

	return apolloClient;
};

/**
 * Creates and provides the apolloContext
 * to a next.js PageTree. Use it by wrapping
 * your PageComponent via HOC pattern.
 * @param {Function|Class} PageComponent
 * @param {Object} [config]
 * @param {Boolean} [config.ssr=true]
 */
export const withApollo = (
	PageComponent: NextPage<any>,
	{
		ssr = true,
	}: {
		ssr?: boolean;
	} = {},
) => {
	const ApolloWrapper = ({
		apolloClient: wrapperClient,
		apolloState,
		...pageProps
	}: {
		apolloClient: any;
		apolloState: any;
		pageProps: any;
	}): React.ReactElement<React.ComponentProps<any>, any> => {
		const client =
			wrapperClient ||
			initApolloClient({
				initialState: apolloState,
			});
		return (
			<ApolloProvider client={client}>
				<PageComponent {...pageProps} />
			</ApolloProvider>
		);
	};

	// Set the correct displayName in development
	if (process.env.NODE_ENV !== 'production') {
		const displayName =
			PageComponent.displayName || PageComponent.name || 'Component';

		if (displayName === 'App') {
			// eslint-disable-next-line no-console
			console.warn('This withApollo HOC only works with PageComponents.');
		}

		ApolloWrapper.displayName = `withApollo(${displayName})`;
	}

	if (PageComponent.getInitialProps) {
		ApolloWrapper.getInitialProps = async ctx => {
			const {AppTree} = ctx;
			const {token, customerId} = getAuthCookies(nextCookie(ctx));
			// Initialize ApolloClient, add it to the ctx object so
			// we can use it in `PageComponent.getInitialProp`.
			ctx.apolloClient = initApolloClient({
				token,
				customerId,
			});
			const {apolloClient: wrapperClient} = ctx;
			// Run wrapped getInitialProps methods
			let pageProps = {};
			if (PageComponent.getInitialProps) {
				pageProps = await PageComponent.getInitialProps(ctx);
			}

			// Only on the server:
			if (typeof window === 'undefined') {
				// When redirecting, the response is finished.
				// No point in continuing to render
				if (ctx.res && ctx.res.finished) {
					return pageProps;
				}

				// Only if ssr is enabled
				if (ssr) {
					try {
						// Run all GraphQL queries
						const {getDataFromTree} = await import(
							'@apollo/client/react/ssr'
						);
						await getDataFromTree(
							<AppTree
								pageProps={{
									...pageProps,
									apolloClient: wrapperClient,
								}}
							/>,
						);
					} catch (error) {
						// Prevent Apollo Client GraphQL errors from crashing SSR.
						// Handle them in components via the data.error prop:
						// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
						// eslint-disable-next-line no-console
						console.error(
							'Error while running `getDataFromTree`',
							error,
						);
					}
				}
			}

			// Extract query data from the Apollo store
			const apolloState = wrapperClient.cache.extract();
			return {...pageProps, apolloState};
		};
	}

	return ApolloWrapper;
};

withApollo.getApolloClient = ctx => {
	const {token, customerId} = getAuthCookies(nextCookie(ctx));
	const inAppContext = Boolean(ctx.ctx);

	if (process.env.NODE_ENV === 'development') {
		if (inAppContext) {
			// eslint-disable-next-line no-console
			console.warn(
				'Warning: You have opted-out of Automatic Static Optimization due to `withApollo` in `pages/_app`.\n' +
					'Read more: https://err.sh/next.js/opt-out-auto-static-optimization\n',
			);
		}
	}

	const client =
		ctx.apolloClient ||
		initApolloClient({
			token,
			customerId,
		});

	client.toJSON = () => null;

	ctx.apolloClient = client;

	if (inAppContext) {
		ctx.ctx.apolloClient = client;
	}

	return ctx;
};

export const getApolloClient = ctx => {
	const {token, customerId} = getAuthCookies(nextCookie(ctx));
	return initApolloClient({
		token,
		customerId,
	});
};
