import {useContext} from 'react';
import {useMutation} from '@apollo/client';
import {AnalyticsContext} from '../contexts/analytics';
import {AuthContext} from '../contexts/auth';
import {I18nContext} from '../i18n';
import {SHARE_MUTATION} from '../queries/share';
import {MutationShareProductArgs} from '../types/generated-gateway-api-types';
import {NativeShareProductFn, ShareStrategy} from '../types/ShareType';
import {notify, VariantNotify} from '../utils/notify';
import {extractUserAgent, isMobile} from '../utils/userAgent';

type UseShare = {
	canNativeShare: boolean;
	nativeShareProduct: NativeShareProductFn;
	trackShare: (label: string) => Promise<void>;
};

const SHARE_ERROR_MESSAGE = 'Can share content';

const canNativeShare =
	typeof window !== 'undefined' && !!window?.navigator?.share;

export default function share({
	title,
	text,
	url,
}: Omit<ShareData, 'email'>): Promise<void> {
	if (canNativeShare) {
		return navigator.share({
			title,
			text,
			url,
		});
	}
	throw Error(SHARE_ERROR_MESSAGE);
}

export function useShare(productId: string): UseShare {
	const {translate} = useContext(I18nContext);
	const {ga} = useContext(AnalyticsContext);
	const {customerId} = useContext(AuthContext);
	const [shareProductMutation] = useMutation<void, MutationShareProductArgs>(
		SHARE_MUTATION,
	);

	const trackShare = async (label: string) => {
		ga(
			'send',
			'event',
			'Share',
			'Click',
			`share_copy_${label.toLowerCase()}`,
		);

		await shareProductMutation({
			variables: {
				share: {
					productId,
					customerId: customerId ? Number(customerId) : undefined,
					useNatif: canNativeShare,
					isMobile: isMobile(),
					userAgent: extractUserAgent(),
				},
			},
		});
	};

	const nativeShareProduct = async (title: string, url: string) => {
		share({title, url}).catch(async e => {
			if (e.message === SHARE_ERROR_MESSAGE) {
				notify(translate('share.error'), VariantNotify.Error);
			}
		});

		await trackShare(ShareStrategy.Native);
	};

	return {
		canNativeShare,
		nativeShareProduct,
		trackShare,
	};
}
