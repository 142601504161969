import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';

type Props = {
	children: React.ReactNode;
	visible?: boolean;
};

const Fade = ({children, visible = false}: Props) => (
	<AnimatePresence>
		{visible && (
			<motion.div
				initial={{
					opacity: 0,
					animationDuration: '0.6s',
					animationTimingFunction: 'easeInOut',
				}}
				animate={{
					opacity: 1,
					animationDuration: '0.6s',
				}}
				exit={{
					opacity: 0,
					animationTimingFunction: 'easeInOut',
				}}
			>
				{children}
			</motion.div>
		)}
	</AnimatePresence>
);

export default Fade;
