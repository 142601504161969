import type {PropsWithChildren} from 'react';
import React, {createContext, useState, useMemo} from 'react';
import type {PageType} from '../types/PageType';

export const NavigationContext = createContext<{
	activeItem: string | null | undefined;
	setActiveItem: (arg0: string) => void;
}>({
	activeItem: null,
	setActiveItem: () => null,
});

if (process.env.NODE_ENV === 'development') {
	NavigationContext.displayName = 'NavigationContext';
}

type Props = PropsWithChildren<unknown>;
export const NavigationContextProvider = ({children}: Props) => {
	const [activeItem, setActiveItem] = useState<string>();
	const providerValue = useMemo(
		() => ({activeItem, setActiveItem}),
		[activeItem, setActiveItem],
	);

	return (
		<NavigationContext.Provider value={providerValue}>
			{children}
		</NavigationContext.Provider>
	);
};
export const withNavigation = (Component: PageType) => {
	const Wrapper = (props: any) => (
		<NavigationContextProvider>
			<Component {...props} />
		</NavigationContextProvider>
	);

	if (Component.getInitialProps) {
		Wrapper.getInitialProps = async ctx =>
			Component.getInitialProps ? Component.getInitialProps(ctx) : {};
	}

	return Wrapper;
};
