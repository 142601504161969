import React, {useMemo} from 'react';
import type {SizeByBreakpoint} from '../../../utils/image-utils';
import {
	computeCloudinaryPictureSrcSet,
	computeCloudinaryWebpSrcSet,
	computeCloudinaryPictureUri,
	computeSizes,
} from '../../../utils/image-utils';

type Props = {
	name: string;
	alt: string;
	className?: string;
	srcSetSizes: number[];
	sizeDefault: string;
	sizeByBreakpoint?: SizeByBreakpoint;
	width?: string;
	height?: string;
	loading?: 'lazy' | 'eager';
};

const CloudinaryResponsiveImage = ({
	name,
	alt,
	className,
	srcSetSizes = [128, 256, 512, 1024],
	sizeDefault = '100vw',
	sizeByBreakpoint,
	width,
	height,
	loading = 'lazy',
}: Props) => {
	const srcSet = useMemo(
		() => computeCloudinaryPictureSrcSet(name, srcSetSizes),
		[name, srcSetSizes],
	);

	const webpSrcSet = useMemo(
		() => computeCloudinaryWebpSrcSet(name, srcSetSizes),
		[name, srcSetSizes],
	);

	const src = useMemo(() => computeCloudinaryPictureUri(name), [name]);
	const sizes = useMemo(
		() => computeSizes(sizeDefault, sizeByBreakpoint),
		[sizeDefault, sizeByBreakpoint],
	);
	return (
		<picture>
			<source sizes={sizes} srcSet={webpSrcSet} type="image/webp" />
			<img
				sizes={sizes}
				src={src}
				srcSet={srcSet}
				alt={alt}
				className={className}
				width={width}
				height={height}
				loading={loading}
			/>
		</picture>
	);
};

export default CloudinaryResponsiveImage;
