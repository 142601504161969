import {gql} from '@apollo/client';
import {paperFragment} from './paper';
import {bargainingFragment, counterBargainingFragment} from './bargaining';
import {productFragment} from './productFragment';

export const productPapersFragment = gql`
	fragment ProductPapersFragment on Product {
		id
		papers {
			...PaperFragment
		}
		sellerId
	}
	${paperFragment}
`;
export const refinedProductFragment = gql`
	fragment RefinedProductFragment on Product {
		id
		price
		priceArgus
		commission
		publicationDate
		websiteStatus
		internalStatus
		thumb
		exclusivityEndDate
		outgoingAgentSlice
		contract {
			pic
		}
		detail {
			id
			car {
				brand
				version
				generation
				carPackage
				energy
				gearbox
				motorization
			}
			critAir
			entryIntoServiceDate
			mileage
		}
		papers {
			...PaperFragment
		}
		bargainings {
			...BargainingFragment
			counterBargaining {
				...CounterBargainingFragment
			}
		}
		ads {
			id
			link
			source
			view
		}
		lastPriceDropByCustomer {
			initialPrice
			newPrice
			date
			fromCustomer
		}
		inspector {
			id
		}
		agent {
			id
			phone
			pic
			firstName
			lastName
		}
		fundsPayins {
			id
			executionDate
			wireReference
			currency
			debitedFunds
		}
		fundsPayouts {
			id
			executionDate
			wireReference
			currency
			creditedFunds
		}
		capcarViewsIn72hrs
		isRepublishedBefore72hrs
	}
	${paperFragment}
	${bargainingFragment}
	${counterBargainingFragment}
`;
export const PRODUCT_QUERY = gql`
	query product($id: String!) {
		product(id: $id) {
			...RefinedProductFragment
		}
		${refinedProductFragment}
	}
`;
export const BOOKING_PRODUCT_QUERY = gql`
	query product($id: String!) {
		product(id: $id) {
			id
			price
			commission
			websiteStatus
			internalStatus
			thumb
			detail {
				car {
					brand
					version
					generation
					carPackage
					motorization
					energy
					gearbox
					power
				}
				mileage
				entryIntoServiceDate
				warrantyExpirationDate
			}
			warranties {
				type
				duration
				amount
			}
			agent {
				id
				phone
				pic
				firstName
				lastName
			}
		}
	}
`;
export const PRODUCT_DETAIL_QUERY = gql`
	query product($id: String!) {
		product(id: $id) {
			id
			websiteStatus
			internalStatus
			publicationDate
			examinationDate
			price
			commission
			hasVideo
			inspector {
				id
				firstName
				pic
			}
			agent {
				id
				pic
				phone
				firstName
				lastName
			}
			department {
				name
				id
			}
			warranties {
				type
				duration
				amount
			}
			brakes {
				id
				isDrum
				disks
				brakePadWear
				brakePadWearCustom
				position
				comment
			}
			detail {
				id
				batteryCapacity
				batteryIsRental
				batteryRentalPrice
				critAir
				car {
					brand
					version
					generation
					carPackage
					motorization
					energy
					gearbox
					power
					dinpower
				}
				co2Emissions
				doorsCount
				mileage
				hasConsistentSerialCg
				exteriorColor
				interiorMaterial
				interiorColor
				isFourWheelDrive
				isBig
				isExpensive
				entryIntoServiceDate
				hasDuplicateKeys
				hasConstructorWarranty
				warrantyExpirationDate
				ownerManual
				ownerManualBills
				spareWheel
				fiscalPower
				gearsNumber
				seatsNumber
				maintenanceNotation
				histovecReportUrl
				lastTechnicalInspectionDate
				recoverableVat
				isImported
				firstHand
				fromCountry
			}
			exterior {
				id
				doorsOpeningState
				trunkOpeningState
				sideLightsState
				headLightsState
				blinkersState
				brakeLightsState
				rearLightsState
				plateLightingState
				wipersState
				exteriorNotation
				comment
			}
			interior {
				otherWarningLight
				oilLevelLight
				brakePadLight
				absLight
				exhaustSystemLight
				engineLight
				airbagLight
				indoorLightingState
				electricWindows
				centralLocking
				radioState
				klaxonState
				gloveBoxState
				seatsCarAdjustingState
				interiorNotation
				comment
			}
			undercarriages {
				position
				cardansInGoodConditions
				isDamperInGoodConditions
				isSteeringBallJointInGoodConditions
				comment
			}
			mechanic {
				distributionKind
				oilLevel
				brakeFluidLevel
				coolingLiquidLevel
				hasNoOilLeak
				beltState
				batteryVoltagePowerOff
				expectedBeltChangeDate
				expectedBeltChangeKm
				expectedNextInspectionDate
				expectedNextInspectionKm
				comment
			}
			option {
				airConditioner
				bluetooth
				regulator
				frontRadar
				rearRadar
				rearViewCamera
				panoramicSunroof
				openableSunroof
				metallicPaint
				alloyWheels
				powerSeats
				heatedSeats
				powerMirrors
				electricallyFoldableRearViewMirrors
				automaticHeadlights
				xenonHeadlights
				automaticWipers
				usbAudioConnector
				gps
				hitch
				speedLimiter
				blindSpotAssist
				ledDippedBeamAndMainBeam
				parkAssist
				powerLiftgate
				headUpDisplay
				comment
				customOptions
			}
			carOptions {
				category
				label
			}
			ownerHistory {
				ownersNumber
				owners {
					startDate
					km
					country
					region
					buyFrom
					purchaseCountry
				}
				notation
				comment
			}
			test {
				isDrivingKeepingInLineAndOnlineBraking
				hasNoVibrationInSteeringColumnAndSuspension
				normalBehaviourOfDirection
				riseHotWithLinearAcceleration
				temperatureIncreaseNormally
				motorHasNoAbnormalsVibrations
				hasGoodGripOfTheClutch
				maximumSpeedReachedDuringTest
				comment
			}
			wheels {
				position
				brand
				width
				height
				diameter
				depth
				tireType
				comment
			}
			maintenances {
				id
				km
				date
				kinds
				comment
			}
			thumb
			pictures {
				index
				kind
				pic
			}
			defects {
				id
				schema
				kind
				pic
				px
				py
				version
				descriptions {
					kind
					detail
					size
				}
				size
				comment
			}
			bargainings {
				id
				status
				counterBargaining {
					offerId
					status
				}
			}
			isMyProduct
		}
		productsLikeThis(id: $id) {
			id
		}
	}
`;
export const MY_PRODUCT_QUERY = gql`
    query myProduct($id: String!) {
        profile {
            myProduct(id: $id) {
                ...RefinedProductFragment
            }
        }
        ${refinedProductFragment}
    }
`;
export const DOCUMENTS_DASHBOARD_QUERY = gql`
	query documentsDashboard($paperKinds: [String!]!) {
		profile {
			myProducts {
				id
				detail {
					id
					car {
						brand
						version
					}
				}
				papers(kinds: $paperKinds) {
					id
					kind
					status
				}
			}
		}
	}
`;
export const MY_QUOTATIONS_AND_PRODUCTS_QUERY = gql`
    query myProducts {
        profile {
            myProducts {
                createdAt
                ...ProductFragment
            }
            myQuotations {
                id
                brand
                version
                energy
                entryIntoService
                gearType
                door
                packageName
                traction
                generation
                mileage
                createdAt
	            price
                appointmentAvailabilities {
	                id
	                slots {
		                rank
	                }
                }
	            product {
	    			id
	    			inspection {
					   id
				    }
    			}
                canUpdate
            }
        }
        ${productFragment}
    }
`;
export const PRICE_DECREASE_MUTATION = gql`
	mutation ($id: String!, $update: DecreasePriceInput!) {
		decreasePrice(id: $id, update: $update) {
			id
			price
		}
	}
`;
export const MY_PRODUCT_PAPERS_QUERY = gql`
    query myProduct($id: String!) {
        profile {
            myProduct(id: $id) {
                ...ProductPapersFragment
            }
        }
        ${productPapersFragment}
    }
`;

export const FAVORITE_PRODUCTS_QUERY = gql`
	query favoriteProducts($favoriteListId: UUID!) {
		favoriteProducts(favoriteListId: $favoriteListId) {
			productId
			addedByUserAt
		}
	}
`;

export const ADD_FAVORITE_PRODUCT = gql`
	mutation ($favoriteListId: UUID!, $productId: String!) {
		addFavoriteProduct(
			favoriteListId: $favoriteListId
			productId: $productId
		)
	}
`;

export const REMOVE_FAVORITE_PRODUCT = gql`
	mutation ($favoriteListId: UUID!, $productId: String!) {
		removeFavoriteProduct(
			favoriteListId: $favoriteListId
			productId: $productId
		)
	}
`;
