import {getReactBreakpoints} from '../breakpoints.config';
import config from '../config';

export const CLOUDINARY_PARAMS = {
	w300: 'w_300,q_auto,f_auto',
	w512: 'w_512,q_auto,f_auto',
};

const {CLOUDINARY_NAME} = config;

const breakpoints = getReactBreakpoints();

export const computeCloudinaryPictureUri = (
	id: string,
	params?: string,
): string =>
	`https://res.cloudinary.com/${CLOUDINARY_NAME}/${
		params || CLOUDINARY_PARAMS.w512
	},fl_progressive:semi/${id}`;

export const computeCloudinaryThumbnailPictureUri = (id: string): string =>
	`https://res.cloudinary.com/${CLOUDINARY_NAME}/c_thumb,w_100,h_50,f_auto,fl_progressive:semi/${id}`;

export const computeCloudinaryPictureSrcSet = (
	id: string,
	srcSetSizes: number[],
): string =>
	srcSetSizes
		.reduce(
			(acc, srcSetSize) =>
				acc.concat(
					`https://res.cloudinary.com/${CLOUDINARY_NAME}/w_${srcSetSize},q_auto,f_auto,fl_progressive:semi/${id} ${srcSetSize}w, `,
				),
			'',
		)
		.slice(0, -2);

export const computeCloudinaryWebpSrcSet = (
	id: string,
	srcSetSizes: number[],
): string =>
	srcSetSizes
		.reduce(
			(acc, srcSetSize) =>
				acc.concat(
					`https://res.cloudinary.com/${CLOUDINARY_NAME}/w_${srcSetSize},q_auto,f_auto,fl_progressive:semi/${id.replace('.jpg', '.webp')} ${srcSetSize}w, `,
				),
			'',
		)
		.slice(0, -2);

export type SizeByBreakpoint = Record<string, string>;

export const computeSizes = (
	sizeDefault: string,
	sizeByBreakpoint?: SizeByBreakpoint,
): string => {
	if (!sizeByBreakpoint) {
		return sizeDefault;
	}

	return Object.keys(sizeByBreakpoint)
		.sort((a, b) => breakpoints[b] - breakpoints[a])
		.reduce(
			(acc, key) =>
				acc.concat(
					`(max-width: ${breakpoints[key]}px) ${sizeByBreakpoint[key]}, `,
				),
			'',
		)
		.concat(`${sizeDefault}`);
};
