import {useState} from 'react';

export const useToggle = initialIsVisible => {
	const [state, setState] = useState(initialIsVisible);

	const toggleState = () => {
		setState(oldState => !oldState);
	};

	return Object.freeze([state, toggleState]);
};
