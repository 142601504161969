import React, {useContext, useState} from 'react';
import {QueryHookOptions, useQuery} from '@apollo/client';
import type {DocumentNode} from 'graphql';
import CenteredContainer from '../../components/system/CenteredContainer';
import Loader from '../../components/system/Loader';
import ErrorMessage from '../../components/system/ErrorMessage';
import {I18nContext} from '../../i18n';

const useQueryHandler = (
	query: DocumentNode,
	node: string,
	options?: QueryHookOptions,
	message?: string,
) => {
	const {translate} = useContext(I18nContext);
	const [optionsState] = useState(options);
	const {data, loading, error} = useQuery(query, optionsState);
	const hasError = error?.graphQLErrors?.length || error?.networkError;
	const errorMessage = message || 'errorLoading';
	let HandlerComponent;
	const errorLoadingJSX = (
		<CenteredContainer>
			<ErrorMessage>{translate(errorMessage)}</ErrorMessage>
		</CenteredContainer>
	);

	if (hasError) {
		HandlerComponent = errorLoadingJSX;
	} else if (loading) {
		HandlerComponent = (
			<CenteredContainer className="h-screen">
				<Loader className="h-20 w-20" />
			</CenteredContainer>
		);
	} else if (!data || !data[node]) {
		HandlerComponent = errorLoadingJSX;
	}

	return {
		data,
		shouldDisplayHandlerComponent: HandlerComponent !== undefined,
		HandlerComponent: () => HandlerComponent,
	};
};

export default useQueryHandler;
