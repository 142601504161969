/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {useState, useEffect} from 'react';
import cx from 'classnames';
import ErrorIcon from '../../../public/static/theme-capcar/times-circle-duotone-2.svg';
import CheckIcon from '../../../public/static/theme-capcar/check-circle-duotone-2.svg';
import TimesIcon from '../../../public/static/svg/icon-close.svg';

type CameraProps = {
	mediaStream: MediaStream;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSnapshotValidated: (img: any) => void;
	onQuitCamera?: () => void;
	photoTitle?: string;
	keepStreamAlive?: boolean;
};

const Camera: React.FC<CameraProps> = ({
	mediaStream,
	onSnapshotValidated,
	onQuitCamera,
	photoTitle,
	keepStreamAlive = false,
}) => {
	const [videoDimensions, setVideoDimensions] = useState<{
		width: number;
		height: number;
	}>({width: 0, height: 0});
	const [capturedPhoto, setCaptured] = useState<string>('');
	const [capturedFormData, setCapturedFormData] = useState<FormData | null>(
		null,
	);
	const getSelectors = (): {
		video: HTMLVideoElement;
		canvas: HTMLCanvasElement;
	} => ({
		video: document.getElementById('camera-video-el') as HTMLVideoElement,
		canvas: document.getElementById(
			'camera-canvas-el',
		) as HTMLCanvasElement,
	});
	const stopStream = () =>
		mediaStream.getTracks().forEach(track => {
			track.stop();
		});
	const startStream = () => {
		const {video, canvas} = getSelectors();

		video.setAttribute('playsinline', 'true');
		video.srcObject = mediaStream;
		video.onloadedmetadata = () => {
			const {clientLeft, clientTop, videoWidth, videoHeight} = video;
			setVideoDimensions({width: videoWidth, height: videoHeight});
			// align canvas position with video position
			canvas.style.position = 'absolute';
			canvas.style.left = clientLeft.toString();
			canvas.style.top = clientTop.toString();
			canvas.setAttribute('width', videoWidth.toString());
			canvas.setAttribute('height', videoHeight.toString());
			video.play();
		};
	};
	const captureImage = async (): Promise<string> => {
		try {
			const {video, canvas} = getSelectors();
			const context = canvas.getContext('2d');
			context?.drawImage(
				video,
				0,
				0,
				videoDimensions.width,
				videoDimensions.height,
			);
			const imageData1 = canvas.toDataURL('image/png', 1.0);
			canvas.toBlob(blob => {
				const formData = new FormData();
				formData.append('File', blob);
				setCapturedFormData(formData);
			});
			setCaptured(imageData1);

			return imageData1;
		} catch (e) {
			// eslint-disable-next-line no-alert
			alert(`Error in Capturing Image:  ${e}`);
			return '';
		}
	};
	const handleClearPicture = () => {
		setCaptured('');
		setCapturedFormData(null);
	};

	const handleValidatePicture = () => {
		onSnapshotValidated(capturedFormData.get('File'));
		setCapturedFormData(null);
		setCaptured('');

		if (!keepStreamAlive) stopStream();
	};

	const handleCloseCamera = () => {
		stopStream();
		if (typeof onQuitCamera === 'function') onQuitCamera();
	};

	useEffect(() => {
		startStream();
	}, []);

	return (
		<div className="video-and-canvas h-screen fixed w-screen inset-0 bg-black z-[53]">
			{photoTitle && (
				<span className="text-white text-xl font-semibold absolute top-8 left-4 drop-shadow-sm z-[52]">
					{photoTitle}
				</span>
			)}

			{onQuitCamera && (
				<a
					className="block bg-gray-100 rounded-full w-14 h-14 absolute top-4 right-4 cursor-pointer z-[52]"
					onClick={handleCloseCamera}
				>
					<TimesIcon className="mx-auto" />
				</a>
			)}

			{capturedPhoto && (
				<img
					src={capturedPhoto}
					className="fixed h-screen w-screen"
					alt="Screenshot"
					style={{objectFit: 'cover'}}
				/>
			)}
			<video
				className="h-screen w-screen"
				style={{objectFit: 'cover'}}
				id="camera-video-el"
			/>
			<canvas style={{opacity: 0}} id="camera-canvas-el" />
			<div
				className={cx('w-full fixed bottom-0 flex z-[51]', {
					'justify-center': !capturedPhoto,
					'justify-around': !!capturedPhoto,
				})}
			>
				{!capturedPhoto && (
					<div
						className="bg-white rounded-full w-14 h-14 cursor-pointer mb-8 border-4 border-gray-100"
						onClick={captureImage}
					/>
				)}
				{capturedPhoto && (
					<>
						<a
							className="block bg-red-300 rounded-full w-14 h-14 cursor-pointer mb-6 text-center"
							onClick={handleClearPicture}
						>
							<ErrorIcon className="mx-auto mt-4" />
						</a>
						<a
							className="block bg-green-300 rounded-full w-14 h-14 cursor-pointer mb-6 text-center"
							onClick={handleValidatePicture}
						>
							<CheckIcon className="mx-auto mt-4" />
						</a>
					</>
				)}
			</div>
		</div>
	);
};

export default Camera;
