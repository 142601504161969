import React, {useState, useCallback, useRef} from 'react';
import cx from 'classnames';
import {useClickAway} from 'react-use';
import TimesIcon from '../../../public/static/svg/times-light.svg';

type Props = {
	title?: string;
	renderButton: (openPopin: () => void) => React.ReactElement;
	className?: string;
};

const Popin: React.FC<React.PropsWithChildren<Props>> = ({
	title,
	renderButton,
	className,
	children,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const handleToggleModal = useCallback(() => setIsOpen(!isOpen), [isOpen]);
	const handleCloseModal = () => setIsOpen(false);
	const ref = useRef(null);

	useClickAway(ref, handleCloseModal);

	return (
		<div className="relative" ref={ref}>
			{renderButton(handleToggleModal)}
			<div
				className={cx(
					'absolute right-2 bg-white menu-shadow px-5 py-4 w-auto rounded-lg whitespace-nowrap',
					{
						'hidden': !isOpen,
					},
					className,
				)}
			>
				<div className="relative pb-5">
					{title && (
						<span className="text-md font-bold mr-12">{title}</span>
					)}
					<button
						onClick={handleCloseModal}
						type="button"
						className="absolute right-1 top-0 p-1"
					>
						<TimesIcon style={{width: '7.8px', height: '7.8px'}} />
					</button>
				</div>
				{React.cloneElement(children as React.ReactElement, {
					closePopin: handleCloseModal,
				})}
			</div>
		</div>
	);
};

export default Popin;
