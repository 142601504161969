import {gql} from '@apollo/client';

export const SHARE_MUTATION = gql`
	mutation shareProductMutation($share: ShareInput!) {
		shareProduct(share: $share) {
			id
			productId
			useNatif
			isMobile
			userAgent
		}
	}
`;
