import {useContext, useEffect, useState, useCallback} from 'react';
import {RequestContext} from '../../contexts/request';

const useMobileCamera = (constraints: MediaStreamConstraints) => {
	const {isMobile} = useContext(RequestContext);
	const [browserCapable, setBrowserCapable] = useState<boolean>(false);
	const getAuthorizationAndStream = useCallback(
		async () =>
			browserCapable &&
			navigator &&
			navigator.mediaDevices.getUserMedia(constraints),
		[constraints, browserCapable],
	);

	useEffect(() => {
		if (navigator) {
			setBrowserCapable(
				'mediaDevices' in navigator &&
					'getUserMedia' in navigator.mediaDevices,
			);
		}
	}, []);

	return {
		capable: isMobile && browserCapable,
		getAuthorizationAndStream,
	};
};

export default useMobileCamera;
