/* eslint-disable react/no-children-prop */
import type {PropsWithChildren} from 'react';
import React, {useContext} from 'react';
import {Waypoint} from 'react-waypoint';
import {NavigationContext} from '../../../contexts/navigation';

type Props = PropsWithChildren<{
	activeItem: string;
	children: React.ReactNode;
}>;

const NavWayPoint = ({activeItem, children}: Props) => {
	const {setActiveItem} = useContext(NavigationContext);
	return (
		<Waypoint
			onEnter={() => setActiveItem(activeItem)}
			topOffset="15%"
			bottomOffset="80%"
			children={children}
		/>
	);
};

export default NavWayPoint;
