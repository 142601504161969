import {useCallback, useState} from 'react';
import {useApolloClient, useMutation} from '@apollo/client';
import {login} from '../../contexts/auth';
import type {CustomerCreationInput} from '../../types/generated-gateway-api-types';
import {computeApiError} from '../../utils/api-errors';
import {CREATE_CUSTOMER} from '../../queries/customer';

export type DataRegistration = CustomerCreationInput & {
	passwordConfirmation: string;
};
type UseRegistrationParams = {
	resetStore?: boolean;
};
type UseRegistrationResponse = {
	errorsRegistration: Record<string, string> | null | undefined;
	loading: boolean;
	registration: (value: DataRegistration) => Promise<boolean>;
};
export const useRegistration = ({
	resetStore,
}: UseRegistrationParams): UseRegistrationResponse => {
	const apolloClient = useApolloClient();
	const [errorsRegistration, setErrorsRegistration] =
		useState<Record<string, string>>();
	const [createCustomer, {loading}] = useMutation(CREATE_CUSTOMER);
	const registration = useCallback(
		async ({
			passwordConfirmation,
			acceptPartner,
			acceptCgu,
			acceptAllianz,
			approachable,
			...customer
		}) => {
			try {
				const {
					data: {createCustomer: newCustomer},
				} = await createCustomer({
					variables: {
						customer: {
							...customer,
							acceptPartner: !!acceptPartner,
							acceptCgu: !!acceptCgu,
							acceptAllianz: !!acceptAllianz,
							approachable: !!approachable,
						},
					},
				});
				login({
					token: newCustomer.token,
					id: newCustomer.customerId,
					firstName: newCustomer.firstName,
					lastName: newCustomer.lastName,
					email: newCustomer.email,
					apolloClient,
					resetStore,
				});
				return true;
			} catch (error) {
				setErrorsRegistration(computeApiError(error));
				return false;
			}
		},
		[],
	);
	return {
		errorsRegistration,
		loading,
		registration,
	};
};
