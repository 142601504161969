import React from 'react';
import range from 'lodash/range';

type Props = {
	rate: number;
};

const TrustRate = ({rate}: Props) => (
	<div className="flex flex-row mt-0_6">
		{range(0, rate).map(i => (
			<span
				key={`rate-${i + 1}`}
				className="rounded-full flex items-center bg-green-410 h-4 w-4 mr-1"
			/>
		))}
		{range(0, 5 - rate).map(i => (
			<span
				key={`rate-${i + rate + 1}`}
				className="rounded-full flex items-center bg-purple-300 h-4 w-4 mr-1"
			/>
		))}
	</div>
);

export default TrustRate;
