import React from 'react';
import Line from './Line';

type Props = {
	currentPosition: number;
	stepNumber: number;
};

const Breadcrumb: React.FC<Props> = ({currentPosition, stepNumber}) => (
	<Line width={`${(currentPosition * 90) / stepNumber}%`} />
);

export default Breadcrumb;
