import formatDate from 'date-fns/format';
import {COUNTRY_OPTION_DEFAULT, INITIAL_VALUES} from './consts';
import type {Customer} from '../../types/generated-gateway-api-types';

export function buildInitialValues(data?: {profile: Customer}) {
	if (!data) return INITIAL_VALUES;
	const {profile} = data;
	const [year, month, day] = profile.birthday
		? formatDate(profile.birthday, 'yyyy-MM-dd').split('-')
		: [INITIAL_VALUES.year, INITIAL_VALUES.month, INITIAL_VALUES.day];
	const address = [
		profile.addressStreet,
		profile.addressZipCode,
		profile.addressCity,
	]
		.filter(a => a)
		.join(', ');
	return {
		...INITIAL_VALUES,
		firstName: profile.firstName,
		lastName: profile.lastName,
		email: profile.email,
		day,
		month,
		year,
		nationality: profile.nationality || COUNTRY_OPTION_DEFAULT,
		address,
		addressStreet: profile.addressStreet || INITIAL_VALUES.addressStreet,
		addressZipCode: profile.addressZipCode || INITIAL_VALUES.addressZipCode,
		addressCity: profile.addressCity || INITIAL_VALUES.addressCity,
		countryOfResidence:
			profile.countryOfResidence || INITIAL_VALUES.countryOfResidence,
		phone: profile.phone,
	};
}

export type InitialValues = ReturnType<typeof buildInitialValues>;
