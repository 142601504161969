import {useApolloClient} from '@apollo/client';
import {useCallback, useContext, useState} from 'react';
import {AnalyticsContext} from '../../contexts/analytics';
import {login} from '../../contexts/auth';
import {fetchPublicApi} from '../../utils/fetchApi';

export type DataConnection = {
	email: string;
	password: string;
};
type UseConnectionParams = {
	resetStore?: boolean;
};
type UseConnectionResponse = {
	errorConnection?: string;
	connection: (arg0: DataConnection) => Promise<boolean>;
};
export const useConnection = ({
	resetStore,
}: UseConnectionParams): UseConnectionResponse => {
	const {ga} = useContext(AnalyticsContext);
	const apolloClient = useApolloClient();
	const [errorConnection, setErrorConnection] = useState();
	const connection = useCallback(
		async ({email, password}: DataConnection) =>
			fetchPublicApi('/login', {
				method: 'POST',
				body: {
					username: email,
					password,
				},
			})
				.then(async res => {
					login({
						token: res.token,
						id: res.id,
						firstName: res.firstName,
						lastName: res.lastName,
						email: res.email,
						resetStore,
						apolloClient,
					});
					return true;
				})
				.catch(async errorResponse => {
					ga(
						'send',
						'event',
						'Authentification',
						'Echec de la connexion',
					);
					setErrorConnection(await errorResponse.json());
					return false;
				}),
		[],
	);
	return {
		errorConnection,
		connection,
	};
};
