import React from 'react';
import cx from 'classnames';
import {ImageLazy} from '../Image';
import Link, {LinkType} from '../Link';

type AnimateIconProps = {
	defaultIconSrc: string;
	hoverIconSrc: string;
	className?: string;
	iconClassName: string;
	href: string;
	type: LinkType;
	alt: string;
	rel?: string;
	target?: string;
	width?: string;
	height?: string;
};

const AnimateIcon = ({
	defaultIconSrc,
	hoverIconSrc,
	iconClassName,
	className,
	href,
	type,
	alt,
	width,
	height,
	...rest
}: AnimateIconProps) => (
	<Link
		type={type}
		href={href}
		className={cx(className, 'relative')}
		prefetch={false}
	>
		<a {...rest}>
			<ImageLazy
				src={defaultIconSrc}
				className={iconClassName}
				alt={alt}
				height={height}
				width={width}
			/>
			<ImageLazy
				src={hoverIconSrc}
				className={cx(
					iconClassName,
					'absolute top-0 right-0 transition duration-300 ease-in-out opacity-0 hover:opacity-100',
				)}
				alt={alt}
				height={height}
				width={width}
			/>
		</a>
	</Link>
);

export default AnimateIcon;
