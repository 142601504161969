import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';

type Props = {
	open: boolean;
	children: React.ReactNode;
};

const Tooltip = ({open, children}: Props) => (
	<AnimatePresence>
		{open && (
			<motion.div
				className="fixed bottom-0 w-full tablet:bottom-auto tablet:absolute right-0 tablet:left-auto left-0 z-80 tablet:-mr-4 tablet:w-64"
				animate={open ? 'open' : 'collapsed'}
				exit="collapsed"
				initial="collapsed"
				key={13}
				transition={{
					duration: 0.1,
				}}
				variants={{
					open: {
						opacity: 1,
						y: 0,
					},
					collapsed: {
						opacity: 0,
						y: 100,
					},
				}}
			>
				{children}
			</motion.div>
		)}
	</AnimatePresence>
);

export default Tooltip;
