import * as React from 'react';
import cx from 'classnames';
import LoaderIcon from '../../../public/static/svg/loader.svg';
import {AnalyticsContext} from '../../../contexts/analytics';
import {TrackingType} from '../../../types/TrackingType';

type VariantType =
	| 'primary'
	| 'secondary'
	| 'tertiary'
	| 'custom'
	| 'green'
	| 'green-inverse';
type ButtonType = 'button' | 'submit' | 'reset';
type ButtonPropsType = {
	id?: string;
	variant?: VariantType;
	className?: string;
	type?: ButtonType;
	children: React.ReactNode;
	classNameChildren?: string;
	isLoading?: boolean;
	disabled?: boolean;
	ariaLabel?: string;
	onClick?: (evt: any) => void;
	tracking?: TrackingType;
	noDisabledBgStyle?: boolean;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonPropsType>(
	(
		{
			variant,
			className,
			children,
			classNameChildren,
			type = 'button',
			isLoading = false,
			disabled = false,
			ariaLabel,
			onClick,
			tracking,
			noDisabledBgStyle,
			...otherProps
		},
		ref,
	) => {
		const {ga} = React.useContext(AnalyticsContext);
		const classes =
			variant !== 'custom'
				? cx(
						'btn focus:outline-none',
						{
							[`btn-${variant}`]: !disabled || noDisabledBgStyle,
							[`btn-${variant}-disabled`]:
								disabled && !noDisabledBgStyle,
							'cursor-not-allowed': disabled || isLoading,
						},
						className,
					)
				: className;
		const contentClassName = `opacity-${isLoading ? '0' : '100'}`;

		const handleOnClick = e => {
			if (tracking) {
				ga(
					'send',
					'event',
					tracking.category,
					tracking.action || 'click',
					tracking.label,
					tracking.value ? Math.round(tracking.value) : undefined,
				);
			}

			if (onClick) onClick(e);
		};

		return (
			// https://github.com/yannickcr/eslint-plugin-react/issues/1555

			/* eslint-disable react/button-has-type */
			<button
				{...otherProps}
				disabled={disabled || isLoading}
				ref={ref}
				onClick={handleOnClick}
				type={type}
				className={classes}
				aria-label={ariaLabel}
			>
				<span
					className={cx('block', contentClassName, classNameChildren)}
				>
					{children}
				</span>
				{isLoading && (
					<LoaderIcon className="absolute w-auto h-auto stroke-current" />
				)}
			</button>
			/* eslint-enable react/button-has-type */
		);
	},
);

Button.displayName = 'Button';

export default Button;
