import type {ReactNode} from 'react';
import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import StarIcon from '../../../public/static/theme-capcar/star.svg';
import StarHalfIcon from '../../../public/static/theme-capcar/star_half_empty.svg';
import StarHalf2Icon from '../../../public/static/theme-capcar/star_half_empty-2.svg';
import StarBorderIcon from '../../../public/static/theme-capcar/star_empty.svg';

type PropsType = {
	score: number | null | undefined;
	max?: number | null | undefined;
	size?: 'small' | 'large' | 'normal';
	fill?: string;
	handleScore?: (score: number) => void;
	opaqueEmptyIcon?: boolean;
};
const SIZE_MAP = {
	small: 'w-4 h-4',
	normal: 'w-6 h-6',
	large: 'w-10 h-10',
};

const Stars = ({
	score,
	max,
	size = 'normal',
	fill = 'fill-yellow-500',
	handleScore,
	opaqueEmptyIcon = false,
}: PropsType) => {
	const [rating, setRating] = useState(0);
	useEffect(() => {
		if (score !== rating) setRating(score || 0);
	}, [score]);
	const [tempRating, setTempRating] = useState(0);

	if (!score && score !== 0) {
		return null;
	}

	const full = Math.floor(rating);
	const half = rating === full ? 0 : 1;
	const empty = (max || 5) - full - half;
	const className = `${fill} ${SIZE_MAP[size]}`;

	const handleClickScore = (clickScore: number) => {
		if (handleScore) {
			handleScore(clickScore);
			setRating(clickScore);
			setTempRating(clickScore);
		}
	};

	const handleMouseOverScore = (hoverScore: number) => {
		if (handleScore) {
			setTempRating(score);
			setRating(hoverScore);
		}
	};

	const handleMouseOutScore = () => {
		if (handleScore) {
			setRating(tempRating);
		}
	};

	const opaqueStar = (i: number) => (
		<StarIcon
			key={`empty${i}`}
			className={cx(className, 'opacity-50')}
			onClick={() => handleClickScore(i + 1)}
			onMouseEnter={() => handleMouseOverScore(i + 1)}
			onMouseLeave={() => handleMouseOutScore()}
		/>
	);

	const starBorder = (i: number) => (
		<StarBorderIcon
			key={`empty${i}`}
			className={className}
			onClick={() => handleClickScore(rating + i + 1)}
			onMouseEnter={() => handleMouseOverScore(rating + i + 1)}
			onMouseLeave={() => handleMouseOutScore()}
		/>
	);

	const opaqueHalfStar = (i: number) => (
		<span key={`half${i}`} className={cx('relative', SIZE_MAP[size])}>
			<StarHalf2Icon className={cx(className, 'absolute')} />
			<StarIcon className={cx(className, 'opacity-50')} />
		</span>
	);

	const icons: ReactNode[] = [].concat(
		Array.from(
			{
				length: full,
			},
			(x, i) => (
				<StarIcon
					key={`full${i}`}
					className={className}
					onClick={() => handleClickScore(i + 1)}
					onMouseEnter={() => handleMouseOverScore(i + 1)}
					onMouseLeave={() => handleMouseOutScore()}
				/>
			),
		),
		Array.from(
			{
				length: half,
			},
			(x, i) =>
				opaqueEmptyIcon ? (
					opaqueHalfStar(i)
				) : (
					<StarHalfIcon key={`half${i}`} className={className} />
				),
		),
		Array.from(
			{
				length: empty,
			},
			(x, i) => (opaqueEmptyIcon ? opaqueStar(i) : starBorder(i)),
		),
	);
	return <span className="inline-flex whitespace-nowrap">{icons}</span>;
};

export default Stars;
