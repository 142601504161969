import {useMutation, useQuery} from '@apollo/client';
import {useContext} from 'react';
import {useLocalStorage} from 'react-use';
import {AnalyticsContext} from '../contexts/analytics';
import {
	FAVORITE_PRODUCTS_QUERY,
	ADD_FAVORITE_PRODUCT,
	REMOVE_FAVORITE_PRODUCT,
} from '../queries/product';
import type {FavoriteProductView} from '../types/generated-gateway-api-types';
import {generateUUID} from '../utils/uuid';

export function useFavoriteProducts(productId?: string) {
	// SSR rendering : cannot get the user's favorite list of products
	const ssr = typeof window === 'undefined';
	if (ssr) {
		return {
			isFavorite: false,
			toggleFavoriteProduct: () => null,
			loading: true,
		};
	}

	const {ga} = useContext(AnalyticsContext);
	const trackEvent = (label: string) => {
		ga('send', 'event', 'Favoris', 'Click', `click_${label}`);
	};

	const [favoriteListId] = useLocalStorage<string>(
		'favoriteListeId',
		generateUUID(),
		{raw: true},
	);

	const {data, loading} = useQuery<{
		favoriteProducts: FavoriteProductView[];
	}>(FAVORITE_PRODUCTS_QUERY, {
		variables: {
			favoriteListId,
		},
		skip: !favoriteListId,
	});

	const favoriteProducts = data?.favoriteProducts || [];

	const [addFavoriteProduct, {loading: loadingAdd}] = useMutation(
		ADD_FAVORITE_PRODUCT,
		{
			refetchQueries: ['favoriteProducts'],
			onCompleted: () => {
				trackEvent('favoris_success');
			},
		},
	);

	const [removeFavoriteProduct, {loading: loadingRemove}] = useMutation(
		REMOVE_FAVORITE_PRODUCT,
		{
			refetchQueries: ['favoriteProducts'],
			onCompleted: () => {
				trackEvent('remove_favoris_success');
			},
		},
	);

	const isFavorite =
		productId &&
		(favoriteProducts || []).map(l => l.productId).includes(productId);

	const toggleFavoriteProduct = isFavorite
		? (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				e.stopPropagation();
				e.preventDefault();
				trackEvent('remove_favoris');
				removeFavoriteProduct({variables: {productId, favoriteListId}});
			}
		: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				e.stopPropagation();
				e.preventDefault();
				trackEvent('favoris');
				addFavoriteProduct({variables: {productId, favoriteListId}});
			};

	return {
		favoriteProducts,
		isFavorite,
		toggleFavoriteProduct: productId
			? toggleFavoriteProduct
			: () => {
					/* When productId is missing, toggleFavoriteProduct action does nothing (silently) */
				},
		loading: loadingAdd || loadingRemove || loading,
	};
}
